import { Box, Fade, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CountDown } from "./countdown";
import mobileImage from "../assets/images/mobile.jpeg";
import desktopImage from "../assets/images/desktop.jpeg";
import { ButtonAppBar } from "./buttonAppBar";

export const HomeSection = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        height: "100vh",
        width: "100%",
        margin: 0,
        padding: 0,
        backgroundImage: `url(${isMobile ? mobileImage : desktopImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="start"
        height="100vh"
      >
        <Typography
          variant={isMobile ? "h4" : "h2"}
          sx={{ letterSpacing: "0.1rem", fontWeight: 300, mt: 12, px: 3 }}
          align="center"
        >
          Hochzeit von Vivien & Markus
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={3}
          justifyContent={"end"}
        >
          <CountDown />
        </Box>
      </Box>
    </Box>
  );
};
