import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export const ButtonAppBar = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleClick = (id) => {
    setIsDrawerOpen(false);
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setIsDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <List>
              {props.ids.map((id) => {
                return (
                  <ListItem button onClick={() => handleClick(id)}>
                    <ListItemText primary={id} />
                  </ListItem>
                );
              })}
            </List>
          </Drawer>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
