import {MainPage} from "./components/mainPage";
import {CssBaseline, Container, Box, ThemeProvider, createTheme} from "@mui/material"

const theme = createTheme({
  palette: {
    primary: {
      main: '#527664',
    },
    secondary: {
      main: '#C38941',
    },
    background: {
      default: '#FFFFFF',
    },
    info: {
      main: '#C38941', // Standardfarbe (z.B. ein Blau)
      light: '#C38941', // hellere Variante
      dark: '#C38941', // dunklere Variante
      contrastText: '#C38941', // Textfarbe auf Info-Hintergrund
    },
  },
});

function App() {
  return (
    <>
    <main>
      <ThemeProvider theme={theme}>
        <CssBaseline></CssBaseline>
        <Box width='100%' padding={0} margin={0}>
          <MainPage></MainPage>
      </Box>
      </ThemeProvider>
    </main>
    </>
  );
}

export default App;
