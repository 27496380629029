import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { SectionContainer } from "./sectionContainer";
import { FoodText } from "./foodText";

export const MenuSection = (props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <SectionContainer
        headline={props.id}
        subheadline="Hier findet ihr Naschkätzchen unser schmackhaftes Hochzeitsmenü."
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          px={isTablet ? 1 : 20}
        >
          <FoodText
            headline="APPETIZER"
            items={[["", "Baguette mit feinen Aufstrichen"]]}
          />
          <FoodText
            headline="VORSPEISE"
            items={[["", "Kartoffelsuppe mit Baguette"]]}
          />
          <FoodText
            headline="HAUPTSPEISE"
            items={[
              [
                "Menü 1",
                "Vegane Semmelknödel mit Pilzrahmsoße, Empfehlung vom Brautpaar",
              ],
              [
                "Menü 2",
                "Großer Blattsalat mit Tomaten, Gurken und Paprika-Mix, dazu gebratener Ziegenkäse, karamellisierte Walnüsse und Kräuterbaguette",
              ],
              [
                "Menü 3",
                "Schweinefiletmedaillons mit Butterspätzle und Pilzrahmsoße",
              ],
            ]}
          />
          <FoodText
            headline="NACHSPEISE"
            items={[["", "Hochzeitstorte und Kuchenbuffet"]]}
          />
          <Typography
            variant="body2"
            pt={2}
            px={1}
            sx={{ fontStyle: "italic", textAlignLast: "center" }}
            textAlign="justify"
          >
            Falls ihr Lust habt, mit einer eigenen Kuchenkreation zum Buffet
            beizutragen, freuen wir uns sehr! Meldet euch einfach bei uns.
          </Typography>
        </Box>
      </SectionContainer>
    </>
  );
};
