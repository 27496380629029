import React from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Avatar,
} from "@mui/material";
import { SectionContainer } from "./sectionContainer";
import PlaceIcon from "@mui/icons-material/Place";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ChurchIcon from "@mui/icons-material/Church";
import CelebrationIcon from "@mui/icons-material/Celebration";
import mainInfoImageTop from "../assets/images/main-info-image-top.jpeg";
import mainInfoImageMiddle from "../assets/images/main-info-image-middle.jpeg";
import mainInfoImageBottom from "../assets/images/main-info-image-bottom.jpeg";
import mainInfoImageTopDesktop from "../assets/images/main-info-image-top-desktop.jpg";
import mainInfoImageMiddleDesktop from "../assets/images/main-info-image-middle-desktop.jpg";
import mainInfoImageBottomDesktop from "../assets/images/main-info-image-bottom-desktop.jpg";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

export const MainInfoSection = (props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down("lg"));

  const paddingX = isTablet ? 0 : isSmallDesktop ? 10 : 30;

  const handleClick = (location) => {
    document.getElementById(location).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box id={props.id}>
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center"
        pt={isTablet ? 8 : 13}
        pb={6}
      >
        <Typography variant="h3" fontWeight={250}>
          V & M
        </Typography>
      </Box>
      <Box display="flex" px={paddingX}>
        <Box
          component="img"
          width="50%"
          height="auto"
          src={isTablet ? mainInfoImageTop : mainInfoImageTopDesktop}
        ></Box>
        <MainInfoItem
          icon={
            <CalendarTodayIcon
              sx={{
                ml: 0.02,
                width: "24px",
                height: "24px",
              }}
            />
          }
          headline="WANN"
          firstRowText="25. Januar 2025"
          secondRowText="13:30 Uhr"
          handleClick={() => handleClick("Tagesablauf")}
        />
      </Box>
      <Box display="flex" px={paddingX}>
        <MainInfoItem
          icon={
            <PlaceIcon
              sx={{
                width: "24px",
                height: "24px",
              }}
            />
          }
          color="secondary"
          headline="WO"
          firstRowText="Schloss Hofstetten"
          secondRowText="Senners Alm"
          handleClick={() => handleClick("Locations")}
        />
        <Box
          component="img"
          width="50%"
          height="auto"
          src={isTablet ? mainInfoImageMiddle : mainInfoImageMiddleDesktop}
        ></Box>
      </Box>
      <Box display="flex" px={paddingX}>
        <Box
          component="img"
          width="50%"
          height="auto"
          src={isTablet ? mainInfoImageBottom : mainInfoImageBottomDesktop}
        ></Box>
        <MainInfoItem
          icon={
            <AssignmentTurnedInIcon
              sx={{
                ml: 0.01,
                width: "24px",
                height: "24px",
              }}
            />
          }
          headline="ZUSAGEN"
          firstRowText="Bis zum 30.11.2024"
          handleClick={() => handleClick("Rückmeldung")}
        />
      </Box>
    </Box>
  );
};

export const MainInfoItem = (props) => {
  const theme = useTheme();

  return (
    <Box
      width="50%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems={"center"}
      onClick={props.handleClick}
    >
      <Avatar sx={{ bgcolor: theme.palette.primary.main }}>{props.icon}</Avatar>
      <Typography
        mt={1}
        textAlign="center"
        variant="h5"
        color={props.color ?? "secondary"}
        pb={2}
      >
        {props.headline}
      </Typography>
      <Typography textAlign="center" fontSize={16} alignSelf={"center"}>
        {props.firstRowText}
      </Typography>
      <Typography textAlign="center" fontSize={16}>
        {props.secondRowText}
      </Typography>
    </Box>
  );
};

export const MainInfoSection1 = (props) => {
  const theme = useTheme();
  return (
    <Box
      width="100%"
      height="100%"
      pt={8}
      px={2}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      <MainInfoBox
        icon={
          <CalendarTodayIcon
            sx={{
              color: "white",
              width: "40px",
              height: "40px",
            }}
          />
        }
        headline="WANN"
        firstRowText="25. Januar 2025"
        secondRowText="13:30 Uhr"
      />
      <MainInfoBox
        icon={
          <PlaceIcon
            sx={{
              color: "white",
              width: "40px",
              height: "40px",
            }}
          />
        }
        headline="WO"
        firstTextIcon={<ChurchIcon sx={{ color: "white", pr: 1 }} />}
        firstRowText="Schloss Hofstetten"
        secondTextIcon={<CelebrationIcon sx={{ color: "white", pr: 1 }} />}
        secondRowText="Senners Alm"
      />
      <MainInfoBox
        icon={
          <AssignmentTurnedInIcon
            sx={{
              color: "white",
              width: "40px",
              height: "40px",
            }}
          />
        }
        headline="Zusagen"
        firstRowText="Bis zum 30. November 2024"
      />
    </Box>
  );
};

const MainInfoBox = (props) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      width={250}
      height={280}
      mb={5}
      sx={{
        borderRadius: "30% 30% 10% 10%",
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Box pb={1}>{props.icon}</Box>
      <Typography textAlign="center" variant="h3" color={"white"} pb={2}>
        {props.headline}
      </Typography>
      <Box display="flex" flexDirection={"row"} justifyContent="center">
        <Box height="100%">{props.firstTextIcon}</Box>
        <Typography
          textAlign="center"
          fontSize={20}
          color={"white"}
          alignSelf={"center"}
        >
          {props.firstRowText}
        </Typography>
      </Box>
      <Box display="flex" flexDirection={"row"} justifyContent="center">
        <Box height="100%">{props.secondTextIcon}</Box>
        <Typography textAlign="center" fontSize={20} color={"white"}>
          {props.secondRowText}
        </Typography>
      </Box>
    </Box>
  );
};
