import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Alert,
  Stack,
  Avatar,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { SectionContainer } from "./sectionContainer";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import HowToVoteIcon from "@mui/icons-material/HowToVote";

export const ReturnSection = (props) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const backgroundColor = theme.palette.primary.main + "80";
  return (
    <>
      <SectionContainer
        headline={props.id}
        subheadline="Bitte teilt uns eure Namen sowie eure bevorzugte Hauptspeise mit."
      >
        <Box
          display="flex"
          flexDirection={isTablet ? "column" : "row"}
          pb={2}
          width="100%"
          px={isTablet ? 0 : 10}
        >
          <ReturnPoint
            icon={
              <CalendarTodayIcon
                sx={{
                  width: "24px",
                  height: "24px",
                }}
              />
            }
            headline="BIS WANN?"
            content={
              <Typography pt={1} textAlign="center">
                Bis zum 30.11
              </Typography>
            }
          />
          <Box onClick={handleClickOpen}>
            <ReturnPoint
              icon={
                <ChatBubbleOutlineIcon
                  sx={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              }
              pt={isTablet ? 4 : 0}
              headline="WORÜBER?"
              content={
                <Typography pt={1} textAlign="center">
                  Über WhatsApp
                </Typography>
              }
            />
          </Box>
          <ReturnPoint
            icon={
              <HowToVoteIcon
                sx={{
                  width: "24px",
                  height: "24px",
                }}
              />
            }
            pt={isTablet ? 4 : 0}
            headline="WIE?"
            content={
              <>
                <Typography pt={1} textAlign="center" fontWeight={"500"}>
                  Beispiel 2 Erwachsene:
                </Typography>
                <Typography textAlign="center" pt={1}>
                  Luna: wählt Menü 1
                </Typography>
                <Typography textAlign="center" pt={1}>
                  Molly: wählt Menü 3
                </Typography>
                <Typography pb={2} textAlign="center">
                  + Glutenunverträglichkeit
                </Typography>
              </>
            }
          />
        </Box>
        <Typography
          pt={1}
          textAlign={"center"}
          fontStyle={"italic"}
          variant="body2"
          px={3}
        >
          Ohne Rückmeldung gehen wir von einer Absage aus.
        </Typography>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>An wen?</DialogTitle>
          <DialogContent display="flex">
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  (window.location.href = "https://wa.me/+4915174384591")
                }
              >
                Vivien
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  (window.location.href = "https://wa.me/+4915174390300")
                }
              >
                Markus
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      </SectionContainer>
    </>
  );
};

const ReturnPoint = (props) => {
  const theme = useTheme();
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        pt={props.pt}
        flexBasis={0}
        flex="1 1 0px;"
      >
        <Avatar
          sx={{ bgcolor: theme.palette.primary.main, alignSelf: "center" }}
        >
          {props.icon}
        </Avatar>
        <Typography
          textAlign="center"
          color="secondary"
          fontWeight={500}
          mt={1}
        >
          {props.headline}
        </Typography>
        {props.content}
      </Box>
    </>
  );
};

/*
        <Box display="flex" flexDirection="column" mx={8}>
          <Paper elevation={6} sx={{ backgroundColor: backgroundColor }}>
            <Typography
              pt={2}
              textAlign="center"
              fontWeight={"500"}
              color="secondary"
            >
              Beispiel:
            </Typography>
            <Typography pt={1.5} textAlign="center" fontWeight={"500"}>
              2 Erwachsene:
            </Typography>
            <Typography textAlign="center" pt={1}>
              Luna: Menü 1
            </Typography>
            <Typography textAlign="center" pt={1}>
              Molly: Menü2
            </Typography>
            <Typography pb={2} textAlign="center">
              + Glutenunverträglichkeit
            </Typography>
          </Paper>
          <Typography variant="body2" pt={2} sx={{ textAlign: "center" }}>
            Ihr könnt eure Rückmeldung einfach per WhatsApp an uns senden.
          </Typography>
        </Box>
        */
