import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { SectionContainer } from "./sectionContainer";
import WeddingIcon from "@mui/icons-material/Church";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CelebrationIcon from "@mui/icons-material/Celebration";

export const ScheduleSection = (props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <SectionContainer
        headline={props.id}
        subheadline="Hier könnt ihr Informationen zu den einzelnen Programmpunkten unserer Hochzeit nachlesen."
      >
        <Timeline position="alternate" sx={{ mt: 0, mx: -2 }}>
          <TimeLineComponent
            time="13:30"
            text="Trauung"
            icon={<WeddingIcon />}
          />
          <TimeLineComponent
            time="14:30"
            text="Gruppenfoto"
            icon={<PhotoCameraIcon />}
          />
          <TimeLineComponent
            time="15:30"
            text="Empfang"
            icon={<LocalBarIcon />}
          />
          <TimeLineComponent
            time="17:30"
            text="Abendessen"
            icon={<RestaurantIcon />}
          />
          <TimeLineComponent
            time="20:00"
            text="Party"
            icon={<CelebrationIcon />}
          />
        </Timeline>
        <Box
          display="flex"
          flexDirection={isTablet ? "column" : "row"}
          pt={isTablet ? 0 : 3}
        >
          <InfoText
            pt={1}
            headline="TRAUUNG"
            text="Wir freuen uns, euch ab 13:00 willkommen zu heißen. 
          Die Trauung wird im 2. Stock stattfinden."
          />
          <InfoText
            pt={2}
            headline="GRUPPENFOTO"
            text="Nach der Trauung würden wir gerne mit allen Gästen ein Gruppenfoto vor dem Schloss machen. 
            Während des Empfangs haben wir dann die Gelegenheit, individuelle Fotos mit allen, die möchten, zu machen."
          />
          <InfoText
            pt={2}
            headline="EMPFANG"
            text="Zur Begrüßung laden wir euch herzlich zu einem Glühweinempfang
            ein, begleitet von Baguette mit Obazda & mediterranen Aufstrich. Je nach Wetterlage findet der Empfang im Freien statt. 
            Bitte denkt daran, warme Kleidung und Schuhe mitzunehmen."
          />
        </Box>
      </SectionContainer>
    </>
  );
};

const TimeLineComponent = (props) => {
  const theme = useTheme();
  return (
    <TimelineItem>
      <TimelineOppositeContent sx={{ mt: 1.5 }}>
        {props.time}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary">{props.icon}</TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ mt: 1.5, fontWeight: 500 }}>
        {props.text}
      </TimelineContent>
    </TimelineItem>
  );
};

const InfoText = (props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      px={isTablet ? 2 : 4}
      pt={isTablet && props.pt ? props.pt : 0}
      flexBasis={0.5}
      flex="1 1 0px;"
    >
      <Typography
        color="secondary"
        pb={0.5}
        sx={{ fontWeight: 500 }}
        textAlign="center"
      >
        {props.headline}
      </Typography>
      <Typography variant="body2" textAlign="justify">
        {props.text}
      </Typography>
    </Box>
  );
};
