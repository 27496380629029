import React from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  Alert,
  useMediaQuery,
} from "@mui/material";
import { SectionContainer } from "./sectionContainer";
import castleImage from "../assets/images/schloss-hofstetten.jpg";
import castleImageDesktop from "../assets/images/schloss-hofstetten-desktop.jpg";
import locationImage from "../assets/images/senners-alm.jpg";
import locationImageDesktop from "../assets/images/senners-alm-desktop.jpg";

export const LocationSection = (props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <SectionContainer
        headline={props.id}
        subheadline="Hier findet ihr Details zu den einzelnen Locations sowie Anreiseinformationen."
      >
        <Box
          display="flex"
          flexDirection={isTablet ? "column" : "row"}
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={6}
            flexBasis={isTablet ? "auto" : "50%"}
          >
            <Typography
              pb={1}
              variant="h6"
              color={theme.palette.secondary.main}
              fontWeight={"500"}
            >
              Hier heiraten wir
            </Typography>
            <Box
              component="img"
              width={"80%"}
              height="auto"
              src={isTablet ? castleImage : castleImageDesktop}
              sx={{ borderRadius: "6px" }}
            ></Box>
            <Typography pt={1} fontWeight={"500"}>
              Schloss Hofstetten
            </Typography>
            <Typography pt={2} textAlign="center">
              Schloßstraße 28, 85122 Hitzhofen
            </Typography>
            <Box display="flex" justifyContent="center" pt={1} pb={3}>
              <a href="https://maps.app.goo.gl/dX1g6Rv82cePcaKr6">
                <Button
                  variant="outlined"
                  sx={{
                    color: theme.palette.secondary.main,
                    borderColor: theme.palette.secondary.main,
                  }}
                >
                  Karte
                </Button>
              </a>
            </Box>
            <Alert severity="info">Keine Pfenningabsätze erlaubt</Alert>
            <Typography
              pt={1}
              textAlign={"center"}
              fontStyle={"italic"}
              variant="body2"
              px={3}
            >
              Diese können den Boden des Gebäudes aus dem 17. Jhd beschädigen.
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            flexBasis={isTablet ? "auto" : "50%"}
          >
            <Typography
              pb={1}
              variant="h6"
              color={theme.palette.secondary.main}
              fontWeight={"500"}
            >
              Hier feiern wir
            </Typography>
            <Box
              component="img"
              width={"80%"}
              height="auto"
              src={isTablet ? locationImage : locationImageDesktop}
              sx={{ borderRadius: "6px" }}
            ></Box>
            <Typography pt={1} fontWeight={"500"}>
              Senners Alm
            </Typography>
            <Typography pt={2} textAlign="center">
              Unterlettenweg 1, 85051 Ingolstadt
            </Typography>
            <Box display="flex" justifyContent="center" pt={1} pb={3}>
              <a href="https://maps.app.goo.gl/kQh2XTUPfZAeSaFN6">
                <Button
                  variant="outlined"
                  sx={{
                    color: theme.palette.secondary.main,
                    borderColor: theme.palette.secondary.main,
                  }}
                >
                  Karte
                </Button>
              </a>
            </Box>
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};
