import React from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Avatar,
} from "@mui/material";
import { SectionContainer } from "./sectionContainer";
import Markus from "../assets/images/Markus.jpeg";
import Vivi from "../assets/images/Vivi.jpeg";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import InstagramIcon from "@mui/icons-material/Instagram";

export const ContactSection = (props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <SectionContainer headline={props.id}>
        <Box
          display="flex"
          flexDirection={isTablet ? "column" : "row"}
          width="100%"
          alignItems={"center"}
        >
          <ContactWithImage
            image={Vivi}
            name="Vivi"
            number="015174384591"
            mail="vivienkoppany@gmail.com"
            instagram="vvnme"
            instagramLink="https://www.instagram.com/vvnme?igsh=MTNjYWpoOWFzMWhxNg=="
          />
          <ContactWithImage
            image={Markus}
            name="Markus"
            number="015174390300"
            mail="neurodermitis1@web.de"
            instagram="hierischmarkus"
            instagramLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&autoplay=1"
          />
        </Box>
      </SectionContainer>
    </>
  );
};

const ContactWithImage = (props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      px={3}
      pb={6}
      flexBasis={isTablet ? "auto" : "50%"}
    >
      <Box
        pb={1.5}
        component="img"
        width="50%"
        height="auto"
        borderRadius={"50%"}
        src={props.image}
      ></Box>
      <Typography
        color="secondary"
        variant="h5"
        fontWeight={500}
        sx={{ textAlign: "center" }}
      >
        {props.name}
      </Typography>
      <a
        href={`tel:${props.number}`}
        style={{ textDecoration: "none !important", color: "black" }}
      >
        <Box display="flex" pt={2}>
          <Avatar
            sx={{
              width: 28,
              height: 28,
              bgcolor: theme.palette.primary.main,
              alignSelf: "center",
            }}
          >
            <PhoneAndroidIcon color="white" sx={{ width: 16, height: 16 }} />
          </Avatar>
          <Typography pl={1} sx={{ textAlign: "center" }}>
            {props.number}
          </Typography>
        </Box>
      </a>
      <a
        href={`mailto:${props.mail}`}
        style={{ textDecoration: "none !important", color: "black" }}
      >
        <Box display="flex" pt={1}>
          <Avatar
            sx={{
              width: 28,
              height: 28,
              bgcolor: theme.palette.primary.main,
              alignSelf: "center",
            }}
          >
            <MailOutlineIcon color="white" sx={{ width: 16, height: 16 }} />
          </Avatar>
          <Typography pl={1} sx={{ textAlign: "center" }}>
            {props.mail}
          </Typography>
        </Box>
      </a>
      <a
        href={props.instagramLink}
        style={{ textDecoration: "none !important", color: "black" }}
      >
        <Box display="flex" pt={1}>
          <Avatar
            sx={{
              width: 28,
              height: 28,
              bgcolor: theme.palette.primary.main,
              alignSelf: "center",
            }}
          >
            <InstagramIcon color="white" sx={{ width: 16, height: 16 }} />
          </Avatar>
          <Typography pl={1} sx={{ textAlign: "center" }}>
            {props.instagram}
          </Typography>
        </Box>
      </a>
    </Box>
  );
};
