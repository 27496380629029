import React from "react";
import { Box, Typography } from "@mui/material";

export const FoodText = (props) => {
  return (
    <Box
      pt={1}
      pb={2.2}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography color="secondary" sx={{ fontWeight: 500 }}>
        {props.headline}
      </Typography>
      {props.items.map((item) => (
        <>
          {item[0] && (
            <Typography pt={1.2} sx={{ fontWeight: 500 }}>
              {item[0]}
            </Typography>
          )}
          <Typography
            pt={!!item[0] ? 0 : 0.5}
            variant="body2"
            textAlign="center"
          >
            {item[1]}
          </Typography>
        </>
      ))}
    </Box>
  );
};
