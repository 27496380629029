import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { ButtonAppBar } from "./buttonAppBar";
import { HomeSection } from "./homeSection";
import { LocationSection } from "./locationSection";
import { MenuSection } from "./menuSection";
import { ReturnSection } from "./returnSection";
import { FaqSection } from "./faqSection";
import { OurImagesSection } from "./ourImagesSection";
import { WishesSection } from "./wishesSection";
import { ScheduleSection } from "./scheduleSection";
import { ContactSection } from "./contactSection";
import { MainInfoSection } from "./mainInfoSection";
import EndImage from "../assets/images/end-image.jpeg";
import EndImageDesktop from "../assets/images/end-image-desktop.jpg";

export const MainPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const pageIds = [
    "Hauptinfo",
    "Locations",
    "Tagesablauf",
    "Menü",
    "Rückmeldung",
    "Wünsche",
    "Unsere Fotos",
    "Fragen & Antworten",
    "Kontakt",
  ];

  return (
    <Box>
      <ButtonAppBar ids={pageIds} />
      <HomeSection />
      <MainInfoSection id={pageIds[0]} />
      <LocationSection id={pageIds[1]} />
      <ScheduleSection id={pageIds[2]} />
      <MenuSection id={pageIds[3]} />
      <ReturnSection id={pageIds[4]} />
      <WishesSection id={pageIds[5]} />
      <OurImagesSection id={pageIds[6]} />
      <FaqSection id={pageIds[7]} />
      <ContactSection id={pageIds[8]} />
      <Box display="flex" justifyContent="center" mt={5}>
        <Box
          component="img"
          width="100%"
          height="auto"
          src={isMobile ? EndImage : EndImageDesktop}
        ></Box>
      </Box>
    </Box>
  );
};
