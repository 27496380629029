import React, { Children } from "react";
import {
  useTheme,
  Typography,
  Box,
  Divider,
  useMediaQuery,
} from "@mui/material";

export const SectionContainer = ({ headline, subheadline, children }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down("lg"));

  const paddingX = isTablet ? 2 : isSmallDesktop ? 10 : 30;

  return (
    <Box
      id={headline}
      width="100%"
      height="100%"
      pt={isTablet ? 8 : 13}
      px={paddingX}
    >
      <Typography variant="h4" align="center" pb={1}>
        {headline}
      </Typography>
      <Box px={2}>
        <Divider
          sx={{
            borderBottomWidth: 5,
            borderColor: "#527664",
          }}
        ></Divider>
      </Box>
      <Typography
        variant="body2"
        pt={1}
        pb={3}
        px={3}
        sx={{ textAlign: "center" }}
      >
        {subheadline}
      </Typography>
      {children}
    </Box>
  );
};
