import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "@emotion/react";
import { SectionContainer } from "./sectionContainer";

export const FaqSection = (props) => {
  const theme = useTheme();
  return (
    <>
      <SectionContainer headline={props.id} subheadline="">
        <Box px={1}>
          <FaqAccordion
            id={1}
            question="Gibt es einen Dresscode?"
            answer="Tragt was euch am besten gefällt und in dem ihr euch wohlfühlt.
          Warme Schuhe sind empfehlenswert, falls der Empfang draußen stattfinden sollte."
          />
          <FaqAccordion
            id={2}
            question="Bis wann müssen wir zu- oder absagen?"
            answer="Bitte teilt uns bis zum 30. November 2024 mit, ob ihr an unserer Hochzeit teilnehmen könnt, damit wir entsprechend planen können."
          />
          <FaqAccordion
            id={3}
            question="Wird es eine Sitzordnung geben?"
            answer="Ja, es wird eine Sitzordnung geben. Am Eingang befindet sich der Sitzplan, der während des Essens gilt."
          />
          <FaqAccordion
            id={4}
            question="Wer sind eure Trauzeug*innen?"
            answer="Vivis Trauzeugin ist ihre Schwester Vanessa. Markus hat zwei Trauzeugen: Falk, ein fotogener Knabe mit einer nicen Mulletfrisur und Philipp, ein motivierter junger Mann mit einem Bizeps, größer als die Zukunft der meisten Menschen."
          />
          <FaqAccordion
            id={4}
            question="Können wir selbst Fotos machen?"
            answer="Wir möchten euch darum bitten, während der Trauung keine Fotos zu machen, da wir einen professionellen Fotografen/Videografen engagiert haben. Während der Feierlichkeiten könnt ihr gerne eure Handys verwenden, um Erinnerungen zu sammeln. Zusätzlich haben wir Einwegkameras bereitgestellt, die ihr gerne benutzen könnt, um besondere Momente festzuhalten."
          />
          <FaqAccordion
            id={5}
            question="Gibt es ein Gästebuch?"
            answer="Ja, wir haben ein Gästebuch! Ihr könnt euch gerne schon vorab überlegen, was ihr hineinschreiben möchtet. Es liegt eine Polaroidkamera bereit, mit der ihr ein Foto von euch machen und es ins Gästebuch einkleben könnt."
          />
          <FaqAccordion
            id={6}
            question="Sind Spiele geplant und wenn ja wann?"
            answer="Zwischen den Gängen und nach dem Essen ist Zeit für Reden oder das ein
          oder andere Spiel. Bitte stimmt euch mit unseren Trauzeug*innen ab."
          />
          <FaqAccordion
            id={6}
            question="Wo können wir übernachten?"
            answer="Fußläufig entfernt befindet sich die Gaststätte Peterwirt (Pension). Hier könnt ihr gerne eine Anfrage stellen. Alternativ bietet Ingolstadt noch zahlreiche weitere Übernachtungsmöglichkeiten."
          />
          <FaqAccordion
            id={6}
            question="Wäre Markus mal fast Fußballprofi geworden?"
            answer="In der Tat. Leider kam eine schwere Knieverletzung dazwischen. Jetzt ist er jedoch ein nicht ganz so erfolgreicher Kraftsportler."
          />
        </Box>
      </SectionContainer>
    </>
  );
};

const FaqAccordion = (props) => {
  const theme = useTheme();
  const [isExpanend, setIsExpaned] = useState(false);
  return (
    <Accordion
      expanded={isExpanend}
      onChange={(event, isExpanend) => setIsExpaned(isExpanend)}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls={`panel${props.id}-content`}
        id={`panel${props.id}-header`}
      >
        <Typography
          sx={{ color: isExpanend ? theme.palette.secondary.main : "default" }}
        >
          {props.question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2">{props.answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
