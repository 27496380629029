import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  useTheme,
} from "@mui/material";
import { SectionContainer } from "./sectionContainer";
import RedeemIcon from "@mui/icons-material/Redeem";

export const WishesSection = (props) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <SectionContainer
        headline={props.id}
        subheadline="Womit könnt ihr unseren Tag noch schöner machen?"
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography sx={{ textAlign: "center" }} pb={3} fontWeight={500}>
            Das größte Geschenk ist es, <br></br> mit euch zu feiern!
          </Typography>
          <Typography
            pb={2}
            textAlign={"justify"}
            sx={{ textAlignLast: "center" }}
            fontStyle={"italic"}
            variant="body2"
            px={4}
          >
            Falls ihr uns eine Freude machen möchtet, klickt auf den Button für
            eine Inspiration:
          </Typography>
          <Button variant="contained" onClick={handleClickOpen}>
            Inspiration
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{ color: theme.palette.secondary.main }}
            >
              Noch eine kleine Geste?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Wenn ihr unser Sparschwein etwas aufstocken möchtet, danken wir
                euch von Herzen.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </SectionContainer>
    </>
  );
};
