import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SectionContainer } from "./sectionContainer";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import mollyImage from "../assets/images/molly.jpeg";

export const OurImagesSection = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <SectionContainer
        headline={props.id}
        subheadline="Wie ihr sehen könnt, ist es hier im Moment noch ziemlich leer. "
      >
        <Box display="flex" flexDirection="column" alignItems="center" px={3}>
          <Typography
            textAlign="justify"
            sx={{ textAlignLast: "center" }}
            pb={2}
          >
            Nach der Hochzeit werden wir hier die Bilder mit euch teilen. Bis
            dahin seht ihr hier nur Molly.
          </Typography>
          <Box
            component="img"
            width={isMobile ? "80%" : "50%"}
            height="auto"
            src={mollyImage}
            sx={{ borderRadius: "6px" }}
          ></Box>
        </Box>
      </SectionContainer>
    </>
  );
};
